import React from "react";
import './Experience.css'; // Import your CSS file for styling
import uw from '../Images/company_images/University-of-Washington-Logo.png';
import walmart from '../Images/company_images/Walmart_logo.png';


function Experience() {
  // Define an array of experience data
  const experienceData = [
    {
      title: "Bioinformatics Research Assistant",
      logo: uw,
      company: "Bothell, WA",
      dates: "March 2023 - August 2023",
      bulletPoints: ["Built a web platform connecting data providers and ML engineers with Django backend and HTML/CSS/JS frontend",
                     "Guided a team of 4 developers in selecting a monolithic architecture with MVT pattern during design phase of SDLC", 
                     "Created a REST API with Zoom API and Django Rest Framework, simplifying Zoom meeting management",
                     "Accomplished user authentication through the OAuth 2.0 protocol to avoid password sharing for 3rd party services",
                     "Documented full-stack app using a standard template, increasing developer productivity by 20% with clearer code"
                    ],
    },
    {
      title: "Undergraduate Teacher Assistant",
      logo: uw,
      company: "Bothell, WA",
      dates: "March 2023 - July 2023",
      bulletPoints: ["Enhanced comprehension of OOP principles in 80% of students by illustrating real-world applications",
                     "Provided troubleshooting assistance to 30-40 students, aiding in identifying and resolving issues in C++ projects",
                     "Communicated with a professor to ensure fair grading for 40 students' programming projects and exams"],
    },
    {
        title: "Customer Service Associate",
        logo: walmart,
        company: "Monroe, WA",
        dates: "August 2019 - March 2023",
        bulletPoints: ["Provide customer service to daily average of 20+ persons in-store and average of 10+ persons through phone calls ensuring quality service and client satisfaction",
                       " Communicate with an average of 5 people in each department to ensure smooth and accurate transaction experience for clients as they leave with their purchases",
                      ],
      },
    // Add more experience entries as needed
  ];

  return (
    <section className='experience section' id='experience'>
      <h2 className='section-title'>Experience</h2>

      <div className="experience-container">
        {experienceData.map((experience, index) => (
          <div className="experience-card" key={index}>

            <div className="experience-details">
              <img src={experience.logo} alt={`${experience.company} Logo`} className="company-logo" />

              <div className="company-title">
                <h3 className="company">
                    {experience.company}
                </h3>
                <p className="dates">{experience.dates}</p>
              </div>
              <h4 className="role-title">{experience.title}</h4>
              <ul className="bullet-points">
                {experience.bulletPoints.map((point, pointIndex) => (
                  <li key={pointIndex}>{point}</li>
                ))}
              </ul>
            </div>


          </div>
        ))}
      </div>
    </section>
  );
}

export default Experience;
