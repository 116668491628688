import React, { useState } from "react";
import './Certifications.css'; // Import your CSS file for styling
import aws_practitioner from '../Images/certificate_images/aws_practitioner.png';
import az900 from '../Images/certificate_images/az-900.png';
import comptia_network from '../Images/certificate_images/comptia_network.png';
import aws_solutions_architect from '../Images/certificate_images/aws_solutions_architect.png';
import linkedin from '../Images/certificate_images/linked_in.png';
import lambdatest from '../Images/certificate_images/lambdatest.png';

import './Certifications.css'; // Import your CSS file for styling

function Certifications() {
  const [activeDescription, setActiveDescription] = useState(null);

  const certificationsData = [
    {
      title: "AZ-900",
      logo: az900,
      progress: 95,
      description: "This certification covers fundamental Azure cloud concepts",
    },
    {
      title: "AWS Practitioner",
      logo: aws_practitioner,
      progress: 30,
      description: "This certification covers essential AWS cloud computing concepts and services",
    },
    {
      title: "CompTia Network+",
      logo: comptia_network,
      progress: 60,
      description: "This certification covers essential networking concepts and skills",
    },
    {
      title: "Solutions Architect",
      logo: aws_solutions_architect,
      progress: 35,
      description: "This certification covers advanced AWS cloud architecture principles, preparing professionals to design and implement scalable and reliable AWS solutions",
    },
    {
      title: "Test Automation",
      logo: lambdatest,
      progress: 100,
      description: "This certification covers the vital skill of test automation, which is a significant time-saver and a crucial component of CI/CD and agile development",
    },
    {
      title: "DevOps",
      logo: linkedin,
      progress: 100,
      description: "This certification certification covers best practices in software development and IT operations, equipping professionals with the skills to streamline and automate the software delivery process",
    },
    {
      title: "Agile",
      logo: linkedin,
      progress: 100,
      description: "This certification covers Agile methodology and practices, equipping individuals with the skills to effectively manage and deliver projects in an agile environment",
    },
    {
      title: "Scrum Master",
      logo: linkedin,
      progress: 100,
      description: "This certification certification encompasses Scrum servant roles, product vision, team formation, norming sessions, sprint events, backlog management, and burn-up chart use",
    }
    // Add more certifications as needed
  ];

  const toggleDescription = (index) => {
    if (activeDescription === index) {
      setActiveDescription(null);
    } else {
      setActiveDescription(index);
    }
  };

  return (
    <section className='certifications section' id='certifications'>
      <h2 className='section-title'>Certifications</h2>

      <div className='certifications-container'>
        {certificationsData.map((certification, index) => (
          <div
            className={`certifications-content ${activeDescription === index ? 'active' : ''}`}
            key={index}
            onClick={() => toggleDescription(index)}
          >
            <h2 className='certifications-subtitle'>
              {certification.title}
            </h2>
            <img src={certification.logo} alt={certification.title} className="logo"/>
            <div className='progress-container'>
              <progress value={certification.progress} max="100"></progress>
              <div className='certifications-progress'>
                {certification.progress}% Complete
              </div>
            </div>
            <p className='certification-description'>
              {certification.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Certifications;
