import React from "react";
import "./Header.css";
import { Link } from "react-scroll";

const navItems = [
  { target: "home", label: "Home" },
  { target: "about", label: "About" },
  { target: "experience", label: "Portfolio" },
  { target: "certifications", label: "Certificates" },
  { target: "contact", label: "Contact" },
];

function Header() {
  return (
    <header className="header">
      <nav className="nav-bar">
        <div>
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="nav-logo"
          >
            Abdulbosit Anvarov
          </Link>
        </div>

        <div className="nav-menu">
          <ul className="nav-list">
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <Link
                  to={item.target}
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
